.bottomnav {
  padding: 15px;
  margin-bottom: 10px;
  text-align: center;

  &.leftcol{
    flex-wrap: wrap;

    .finanziert{
      flex: 1 0 100%;
      display: flex;
      margin-top: 10px;
      img{
        margin-right: 10px;
      }
      span{
        text-align: left;
        font-size: 13px;
      }
    }
  }
}

.footerlogos{
  overflow: hidden;
  .sachsenlogos{
    flex-wrap: nowrap;
    justify-content: space-evenly;
    a{
      width: 33%;
      text-align: center;
      align-self: center;
    }
    a.logoministerium{
      text-align: left;
      img{
        height: 50px;
      }
    }
    img{
      height: 70px;
    }
  }
  .tothepostslink {
    text-align: center;
    width: 100%;
    .ausklappbutton{
      display: none;
      transform: rotate(180deg);
      &.collapsed{
        transform: rotate(360deg);
      }
    }
  }
  h3, .btn{
    color: #ffffff !important;
  }
}

.socialicons{
  svg{
    width: 40px;
    .cls-3{
      fill: $primary;
    }
  }
}

@media (max-width: 426px) {
  .footerlogos{
    .btn{
      font-size: 11px;
    }
    .tothepostslink .ausklappbutton{
      display: block;
    }
  }
}

@media (min-width: 426px) {
  .footerlogos .multi-collapse{
    display: flex;
  }
}