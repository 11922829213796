.preistraeger{

  .postgridview{
    padding-top: 30px;

      .card .card-body{
        margin-top: 31px !important;
      }

      dt {
        width: 30px !important;
      }
      dd{
        margin-left: 40px !important;
        @include media-breakpoint-down(sm) {
          margin-left: 0px !important;
        }
      }

  }
  svg{
    path {
      fill: $primary;
    }
  }
  .solrsortbar .result-info {
    margin-top: 34px !important;
    border-radius: 5px;
  }
}
