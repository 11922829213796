
#datenmanagementgrafik{
  overflow: visible;
  .col-md-3, .col-6, .col-md-7, .col-12, #grossegrafik{
    position: unset !important;
  }
  .text{
    position: absolute;
    z-index: 1000;
    background-color: #ffffff;
    padding: 25px;
    display: none;
    box-shadow: 5px 15px 10px silver;
    margin-top: 25px;
    left: 10%;
    right: 10%;
    margin-inline: auto;
    width: 80%;

    .closeme{
      position: absolute;
      right: 15px;
      top: 15px;
      color: #000000;
      text-decoration: none;
    }
  }

  img{
    max-width: 100%;
    height: auto;
    cursor: pointer;
  }
}