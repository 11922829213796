.beteiligungsangebote{
  .headbox{
    background-color: $primary;
    padding: 20px;
    *{
      color: #ffffff;
    }
    svg{
      path {
        fill: #ffffff;
      }
    }
  }
  a{
    color: $primary;
  }
  .footbox{
    background-color: $primarylight;
    padding: 20px;

    h3{
      font-size: 20px;
    }
  }
  .thumbnailboxwithquelle {
    width: inherit !important;
    img{
      width: 500px !important;
    }
  }
  .ba-box{
    display: flex;
    div{
      display: inline-block;
    }

    .label{
      flex-shrink: 0  ;
      width: 30px;
      margin-right: 15px;
      text-align: center;
    }
  }
  .button{
    display: inline-block;
    background-color: $primary;
    color: #ffffff;
    border-radius: 0px;
    padding: 3px 8px;
  }
  .page{
    max-width: 1140px !important;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
  }


  .page{
    margin: auto;
    padding: 0px;
  }

  .postgridview{
    padding-top: 30px;

    .type-beteiligungsangebote{
      .gueltigbis{
        text-decoration: underline;
      }
      a{
        color: #000000 !important;
      }
      a:hover{
        text-decoration: none;
        .article{
          background-color: $tertiary;
        }
      }
      .article{
        background-color: $primary;
        padding: 20px;
        min-height: 440px;
        *{
          color: #ffffff !important;
        }
        svg{
          path {
            fill: #ffffff;
          }
        }
      }

      h3{
        font-size: 22px;
      }
      .read-more a{
        font-weight: bold;
      }
      dt {
        width: 30px !important;
      }
      dt.euro{
        width: 25px !important;
      }
      dd{
        margin-left: 40px !important;
        @include media-breakpoint-down(sm) {
          margin-left: 0px !important;
        }
      }
    }
  }
}

.beteiligungsangebote-template-default .headlineheader .date{
  display: none;
}