
.goldenbackground{
  background: $primarylight;
  overflow-x: hidden;
  @include media-breakpoint-down(xs) {
    width: 110%;
  }
  .row{
    overflow-x: hidden;
  }
}

.hintergrundbild{
  *{
    color: #ffffff !important;
  }
  .vc_btn3{
    color: $tertiary !important;
  }
}

.svg200 img{
  width: 200px;
}

.newsletterbox{
  ul{
    list-style: none;

    li{
      display: inline-flex;
      align-items: end;
    }
    li:before{
        content:'';
        display:inline-block;
        height:36px;
        width: 45px;
        background-image:url("images/icons/haken.svg");
        background-size:45px;
        background-repeat:no-repeat;
        padding-left: 2em;
        margin-left: -39px;
    }
  }
}

//ACCODION STYLING
.wpb-js-composer .vc_tta.vc_general {
  .vc_tta-panel-heading{
    border-radius: 0px !important;
    .vc_tta-title-text{
      font-size: 16*$px-base;
      font-weight: bold;
    }
  }
}

html body.wpb-js-composer {
  .vc_tta-tabs.vc_tta-color-green{
    .vc_tta-tab>a{
      background-color: $primaryhover;
    }
    .vc_tta-tab.vc_tta-tab.vc_active>a{
      background-color: $primary;
    }
    .vc_tta-tab.vc_tta-tab>a:hover{
      background-color: $primarylight;
      color: $primary;
    }
    .vc_tta-tab.vc_tta-tab>a:focus{
      outline: 1px solid #2cc4ff;
      box-shadow: 0 0 5px #2cc4ff !important;
      z-index: 50;
      transition: outline .1s linear;
      background-color: $primarylight;
      color: $primary;
    }
    .vc_tta-panels{
      background-color: $primary !important;
    }
  }

  .vc_tta-color-green .vc_tta-panel{
    .vc_tta-panel-heading{
      background-color: $primaryhover !important;
      a:hover{
        background-color: $primarylight;
        color: $primary !important;
        .vc_tta-controls-icon::before, .vc_tta-controls-icon::after{
          color: $primary;
          border-color: $primary;
        }
      }
      a:focus{
        outline: 1px solid #2cc4ff;
        box-shadow: 0 0 5px #2cc4ff !important;
        z-index: 50;
        transition: outline .1s linear;
        background-color: $primarylight;
        color: $primary !important;
        .vc_tta-controls-icon::before, .vc_tta-controls-icon::after{
          color: $primary;
          border-color: $primary;
        }
      }
    }

    &.vc_active .vc_tta-panel-heading{
      background-color: $primary !important;
    }
    .vc_tta-panel-body{
      background-color: $primary !important;
    }
  }
}
