.searchpagebox{
  .solrsortbar{
    background-color: $primarylight;

    *{

    }

    #searchformbig{
      margin: 0px;
      width: 100%;
      margin-bottom: 20px;
      border: none;
      display: flex;

      input{
        padding: 10px;
      }

      #searchtext{
        width: 100%;
        border: none;
        margin-left: 8px;
      }
      input[type="submit"]{
        border: none;
        margin-left: 20px;
        background-color: $primary;
        color: #ffffff;
        @include media-breakpoint-up(lg) {
          width: 356px;
        }
      }
    }

    .result-info{
      background-color: $primary;
      text-align: center;
      padding: 10px;
      color: $secondary;
      margin-top: 20px;
    }

    h3{
      font-size: 16*$px-base;
      font-weight: bold;
    }

    .btn{
      border-radius: 0;
      text-transform: initial;
    }

    .dynamic-facets{

      .facet{
        padding: 10px;

        .form-control{
          border-radius: 0;
          border: none;
          border: solid 1px $secondary;
          background-color: #ffffff;
          background-clip: border-box;
        }
      }

      input{
        width: inherit !important;
      }

      .btn-group{
        button{
          border-radius: 0;
          border: solid 1px $secondary;
          display: flex;
          align-items: center;
          justify-content: space-between;
          background-color: #ffffff;

          .multiselect-selected-text{
            font-weight: normal;
            text-transform: initial;
          }
        }
        ul{
          padding: 10px;
          border-radius: 0;
          border: solid 1px $secondary;
        }
      }
    }
  }


  .paginationbar{
    display: flex;
    justify-content: flex-end;

    .pagination{
      border-radius: 0;
      .page-item{
        .page-link{
          border-radius: 0;
          background-color: $primary;
          color: #ffffff;
          border: none;
        }
        &.active{
          .page-link {
            color: #ffffff;
            font-weight: bold;
          }
        }
      }
    }
  }
}

